import React from 'react'
import { AuthProvider } from './contexts/AuthContext'
import { HashRouter as Router, Redirect, Switch, Route } from 'react-router-dom'
import './App.css'
import PrivateRoute from './components/Auth/PrivateRoute/PrivateRoute';
const Navbar = React.lazy(() => import('./components/UI/NavigationBar/Navbar'));
const Footer = React.lazy(() => import('./components/UI/Footer/Footer'));
const Home = React.lazy(() => import('./components/Home/Home'));
const About = React.lazy(() => import('./components/About/About'));
const Contact = React.lazy(() => import('./components/Contact/Contact'));
const Pricing = React.lazy(() => import('./components/Pricing/Pricing'));
const Book = React.lazy(() => import('./components/Book/Book'));
const Register = React.lazy(() => import('./components/Auth/Register/Register'));
const Login = React.lazy(() => import('./components/Auth/Login/Login'))
const ForgotPassword = React.lazy(() => import('./components/Auth/ForgotPassword/ForgotPassword'))
const AdminProfile = React.lazy(() => import('./components/Auth/Profile/AdminProfile'))
const UserProfile = React.lazy(() => import('./components/Auth/UserProfile/UserProfile'))
const UpdateProfile = React.lazy(() => import('./components/Auth/Profile/UpdateProfile/UpdateProfile'))
const BookingDetails = React.lazy(() => import('./components/Auth/AuthUI/BookingDetails/BookingDetails'))

const  App: React.FC  = () => {
  return (
    <AuthProvider>
      <React.Suspense fallback={<span>Loading ...</span>}>
          <Router>
              <header className="header-container">
                <Navbar/>
              </header>
              <main>
              <Switch>
                <Route exact path="/" render={() => <Home />} />
                <Route exact path="/about" render={() => <About />} />
                <Route exact path="/contact" render={() => <Contact />} />
                <Route exact path="/book" render={() => <Book />} />
                <Route exact path="/pricing" render={() => <Pricing />} />
                <Route exact path="/register" render={() => <Register />} />
                <Route exact path="/login" render={() => <Login />} />
                <Route exact path="/forgotpassword" render={() => <ForgotPassword />} />
                <PrivateRoute exact path="/adminprofile" component={AdminProfile} />
                <PrivateRoute exact path="/userprofile/:id" component={UserProfile}  />
                <PrivateRoute exact path="/updateprofile"component={UpdateProfile}  />
                <PrivateRoute exact path="/bookingdetails/:id" component={BookingDetails}  />
                <Redirect to='/' />
              </Switch>
              </main>
              <Footer />
            </Router>
      </React.Suspense>
      </AuthProvider>
   
  );
}

export default App;
