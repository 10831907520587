import React, {useContext, useState, useEffect} from 'react'
import { auth } from '../firebase'
const AuthContext = React.createContext()


export function useAuth(){
    return useContext(AuthContext)
}
export  function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null)
    const [isUserId,setUserId] = useState(null)
    const [isAdmin,setIsAdmin] = useState(null)
    const [loading,setLoading] = useState(true)
    function signUp(email, password){
        return auth.createUserWithEmailAndPassword(email, password)
    }
    function login(email, password){
        return auth.signInWithEmailAndPassword(email, password)
    }
    function logout(){
       return auth.signOut()
    }
    function resetPassword(email){
        return auth.sendPasswordResetEmail(email)
    }

    function updatePassword(password){
        return currentUser.updatePassword(password)
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged( user => {
            setCurrentUser(user)
            setLoading(false)
        })
        return unsubscribe
    },[])
    let value = {
        currentUser,
        signUp,
        login,
        logout,
        resetPassword,
        updatePassword,
        isUserId,
        setUserId,
        isAdmin,
        setIsAdmin
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
